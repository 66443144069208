import React from 'react'
import { FlexColumn } from 'components/common/Containers'
import styles from './DashboardTitle.module.scss'

const DashboardTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <FlexColumn gap="14px">
      <h1 className={styles.title}>{title}</h1>
      <p>
        Manage your domains, upgrade/downgrade your plans and adjust/edit your
        payment info.
      </p>
    </FlexColumn>
  )
}

export default DashboardTitle
