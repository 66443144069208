import React from 'react'
import Card from 'components/common/Card/Card'
import styles from './DashboardPromo.module.scss'
import { FlexColumn } from 'components/common/Containers'
import Image from 'next/image'

const DashboardPromo = () => {
  return (
    <Card className={styles.promoCard} padding="35px 25px 88px">
      <FlexColumn gap="15px">
        <h5 className={styles.title}>Uprade to Pro</h5>

        <p className={styles.text}>Get 1 month free on annual subscription</p>

        <span className={styles.promoText}>Upgrade Today!</span>
      </FlexColumn>

      <div className={styles.image}>
        <Image
          src="/images/dashboard/rocket.png"
          alt="Pro image"
          width={122}
          height={122}
        />
      </div>
    </Card>
  )
}

export default DashboardPromo
