import { useMutation } from 'react-query'
import { notificationController } from 'notificationController/notificationController'
import { authService } from 'services/auth.service'
import { queryClient } from 'pages/_app'
import { deleteCookie } from 'utils/deleteCookie'
import { APIHooksKeysEnum } from 'enums/APIHooksKeysEnum'

const fetchLogOut = async () => {
  const { data } = await authService.logOut()

  return data
}

export const useLogOut = () => {
  const mutation = useMutation(fetchLogOut, {
    onSuccess: async () => {
      deleteCookie('access')
      await queryClient.clear()
      await queryClient.setQueryData(APIHooksKeysEnum.GET_USER, () => undefined)
    },
    onError: (error) => {
      notificationController.show({
        description: (error as Error).message || 'Failed to log out',
        status: 'error',
      })
    },
  })

  return mutation
}
