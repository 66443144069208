import { MdLabelOutline, MdOutlineSportsBasketball } from 'react-icons/md'
import { AiOutlineShop } from 'react-icons/ai'
import { RiArrowGoBackFill } from 'react-icons/ri'
import { BiWalletAlt } from 'react-icons/bi'
import { BsPersonSquare } from 'react-icons/bs'
import { RouteEnum } from 'enums/RouteEnum'

export const menu = [
  {
    key: "my_plan",
    title: 'My Plan',
    icon: <MdLabelOutline />,
    href: RouteEnum.DASHBOARD_CUSTOMER_PORTAL,
  },
  {
    key: "my_marketplace",
    title: 'My Marketplace',
    icon: <AiOutlineShop />,
    children: [
      {
        key: "my_backorders",
        title: 'My backorders',
        icon: (
          <RiArrowGoBackFill
            style={{ transform: 'rotate(270deg) scaleY(-1)' }}
          />
        ),
        href: RouteEnum.DASHBOARD_BACKORDERS,
      },
      {
        key: "my_domains",
        title: 'My domains',
        icon: (
          <MdOutlineSportsBasketball style={{ transform: 'rotate(90deg)' }} />
        ),
        href: RouteEnum.DASHBOARD_DOMAINS,
      },
    ],
  },
  {
    key: "personal_info",
    title: 'Personal info',
    icon: <BsPersonSquare style={{ padding: '2px' }} />,
    href: RouteEnum.DASHBOARD_PERSONAL_INFO,
  },
  {
    key: "payments",
    title: 'Payments',
    icon: <BiWalletAlt />,
    href: RouteEnum.DASHBOARD_PAYMENTS,
  },
]
