import React from 'react'
import AuthHeader from 'components/Auth/AuthHeader/AuthHeader'
import styles from './AuthLayout.module.scss'

const AuthLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.auth}>
      <AuthHeader />

      <main>{children}</main>
    </div>
  )
}

export default AuthLayout
