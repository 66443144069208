import React from 'react'

import styles from '../Menu.module.scss'
import A from 'components/common/A/A'
import classNames from 'classnames'
import MenuItems from './MenuItems'
import { MenuItem as MenuItemInterface } from '../models/menu-item'

interface Props {
  item: MenuItemInterface
  activeItemKey: string
  setActiveItemKey: (key: string) => void
}

export const MenuItem: React.FC<Props> = ({
  item,
  activeItemKey,
  setActiveItemKey,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <>
      <A
        href={item.href}
        className={classNames(
          styles.menuItem,
          activeItemKey === item.key && styles.active
        )}
        onClick={() => {
          setActiveItemKey(item.key)
          if (item?.children?.length) {
            setIsOpen((o) => !o)
          }
        }}
      >
        {item.icon}
        <span>{item.title}</span>
      </A>
      {item.children && (
        <MenuItems
          isOpen={isOpen}
          items={item.children}
          activeItemKey={activeItemKey}
          setActiveItemKey={setActiveItemKey}
        />
      )}
    </>
  )
}
