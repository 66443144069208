import React, { useRef } from 'react'
import MainLayout from 'layouts/MainLayout/MainLayout'
import { Container, FlexColumn, FlexRow } from 'components/common/Containers'
import DashboardTitle from 'components/Dashboard/DashboardTitle/DashboardTitle'
import DashboardLogOut from 'components/Dashboard/DashboardLogOut/DashboardLogOut'
import DashboardPromo from 'components/Dashboard/DashboardPromo/DashboardPromo'
import DashboardMenu from 'components/Dashboard/DashboardMenu/DashboardMenu'
import { useGetUser } from 'hooks/useGetUser'
import styles from './DashboardLayout.module.scss'
import classNames from 'classnames'

interface Props {
  title: string
}

const DashboardLayout: React.FC<Props> = ({ title, children }) => {
  const { data: user } = useGetUser()
  const containerRef = useRef<HTMLDivElement>()
  const headerRef = useRef<HTMLElement>()

  React.useEffect(() => {
    const scrollListener = () => {
      const container = containerRef?.current
      const header = headerRef?.current

      if (container && header && window) {
        const containerTopOffset = container.offsetTop
        const pageYScroll = window.scrollY
        const hasScrolledPastContainer = pageYScroll > containerTopOffset

        if (hasScrolledPastContainer) {
          header.classList.remove(styles.transparent)
        } else {
          header.classList.add(styles.transparent)
        }
      }
    }
    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      <MainLayout
        headerClassName={classNames(styles.header, styles.transparent)}
        className={styles.main}
        headerRef={headerRef}
      >
        <Container>
          <div className={styles.insideWrapper}>
            <FlexColumn
              className={styles.innerWrapper}
              gap="65px"
              align="normal"
              ref={containerRef}
            >
              <DashboardTitle title={title} />

              <FlexRow
                className={styles.contentWrapper}
                gap="56px"
                align="normal"
                justify="normal"
              >
                <FlexColumn
                  className={styles.leftSide}
                  gap="52px"
                  align="normal"
                >
                  <DashboardMenu />

                  {user?.subscription?.plan === 'free' && <DashboardPromo />}

                  <DashboardLogOut />
                </FlexColumn>

                <div className={styles.content}>{children}</div>
              </FlexRow>
            </FlexColumn>
          </div>
        </Container>
      </MainLayout>
    </div>
  )
}

export default DashboardLayout
