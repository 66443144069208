import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { Container } from 'components/common/Containers/Container/Container'
import { RouteEnum } from 'enums/RouteEnum'
import styles from './AuthHeader.module.scss'

const AuthHeader: React.FC = () => {
  return (
    <header className={styles.header}>
      <Container>
        <Link href={RouteEnum.HOME} passHref>

          <Image
            src="/logos/logo.svg"
            width="131"
            height="25"
            alt="logo"
          />

        </Link>
      </Container>
    </header>
  );
}

export default AuthHeader
