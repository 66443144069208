import axios from 'axios'
import {
  ForgotPasswordDetails,
  ForgotPasswordResponse,
  LogInDetails,
  LogInResponse,
  ResetPasswordDetails,
  SignUpDetails,
  SignUpResponse,
} from '../models/auth.interface'
import { BishopiBaseService } from './bishopi-base.service'

export class AuthService extends BishopiBaseService {
  signUp(details: SignUpDetails) {
    return axios.post<SignUpResponse>(
      this.getPath(`/accounts/auth/signup`),
      details
    )
  }

  logIn(details: LogInDetails) {
    return axios.post<LogInResponse>(
      this.getPath(`/accounts/auth/login`),
      details
    )
  }

  logOut() {
    return axios.post(this.getPath('/accounts/auth/logout'), {})
  }

  forgotPassword(details: ForgotPasswordDetails) {
    return axios.post<ForgotPasswordResponse>(
      this.getPath(`/accounts/forgot-password/`),
      details
    )
  }

  resetPassword(details: ResetPasswordDetails) {
    return axios.put(
      this.getPath(`/accounts/reset-password/${details.token}`),
      {
        password: details.password,
        confirm_password: details.confirm_password,
      }
    )
  }
}

export const authService = new AuthService()
