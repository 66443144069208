import React from 'react'
import MainLayout, { MainLayoutProps } from 'layouts/MainLayout/MainLayout'
import MoreTools from 'components/common/MoreTools/MoreTools'

// Use this layout for every tools (feature) page

const ToolsLayout: React.FC<MainLayoutProps> = ({ children, ...props }) => {
  return (
    <MainLayout {...props}>
      <MoreTools />
      {children}
    </MainLayout>
  )
}

export default ToolsLayout
