import React from 'react'
import MenuItems from './components/MenuItems'
import { MenuItem } from './models/menu-item'

interface MenuProps {
  items: MenuItem[],
  variant?: "sm" // this can be removed when we fix https://github.com/Bishopi-io/bishopi-front-nextjs/issues/42
}

const Menu: React.FC<MenuProps> = ({ items, variant }) => {
  const [activeItemKey, setActiveItemKey] = React.useState<string>()

  return (
    <MenuItems
      variant={variant}
      activeItemKey={activeItemKey}
      setActiveItemKey={setActiveItemKey}
      items={items}
    />
  )
}

export default Menu
