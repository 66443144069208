import React from 'react'
import { menu as menuData } from './data/data'
import Card from 'components/common/Card/Card'
import Menu from "components/common/Menu/Menu";

interface Props {}

const DashboardMenu: React.FC<Props> = ({}) => {
  return (
    <Card padding={[40, 31]}>
      <Menu items={menuData} />
    </Card>
  )
}
export default DashboardMenu
