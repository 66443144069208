import React from 'react'
import classNames from 'classnames'
import { AiOutlineClose } from 'react-icons/ai'
import Dropdown from '../Dropdown/Dropdown'
import useComponentVisible from 'hooks/useComponentVisible'
import SpiderIcon from './icons/spider.svg'
import { FreeToolsData, ProToolsData } from 'components/Header/data'
import styles from './MoreTools.module.scss'
import { FlexColumn, FlexRow } from '../Containers'
import Image from 'next/image'
import Link from 'next/link'

const MoreTools = () => {
  const { isComponentVisible, ref, setIsComponentVisible } =
    useComponentVisible(false)

  const handleOpen = () => setIsComponentVisible((open) => !open)

  return (
    <Dropdown
      isOpen={isComponentVisible}
      triggerNode={
        <div
          ref={ref}
          className={classNames(
            styles.triggerWrapper,
            isComponentVisible && styles.active
          )}
          onClick={handleOpen}
        >
          {!isComponentVisible ? (
            <>
              <SpiderIcon /> QUICK TOOLS
            </>
          ) : (
            <AiOutlineClose />
          )}
        </div>
      }
      withArrow={false}
      withClose={false}
      placement="auto-end"
    >
      <FlexColumn className={styles.contentWrapper} gap="0" align="normal">
        <FlexColumn className={styles.section} gap="5px" align="normal">
          <span className={styles.title}>Free Tools</span>
          <FlexColumn className={styles.content} align="normal" gap="5px">
            {FreeToolsData.map((item) => (
              (<Link key={item.head} href={item.href}>

                <FlexRow className={styles.item} align="center" gap="12px">
                  <Image
                    src={item.src}
                    alt={item.head}
                    width={20}
                    height={20}
                  />
                  {item.head}
                </FlexRow>

              </Link>)
            ))}
          </FlexColumn>
        </FlexColumn>

        <FlexColumn className={styles.proTools} gap="5px" align="normal">
          <span className={styles.title}>Pro Tools</span>
          <FlexColumn className={styles.content} align="normal" gap="5px">
            {ProToolsData.map((item) => (
              (<Link key={item.head} href={item.href}>

                <FlexRow className={styles.item} align="center" gap="12px">
                  <Image
                    src={item.src}
                    alt={item.head}
                    width={20}
                    height={20}
                  />
                  {item.head}
                </FlexRow>

              </Link>)
            ))}
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>
    </Dropdown>
  );
}

export default MoreTools
