import React from 'react'
import classNames from 'classnames'

import styles from '../Menu.module.scss'
import { MenuItem } from './MenuItem'
import { MenuItem as MenuItemInterface } from '../models/menu-item'

interface Props {
  items: MenuItemInterface[]
  activeItemKey: string
  setActiveItemKey: (key: string) => void
  variant?: 'sm' // this can be removed when we fix https://github.com/Bishopi-io/bishopi-front-nextjs/issues/42

  isOpen?: boolean
}

const MenuItems: React.FC<Props> = ({
  items,
  activeItemKey,
  setActiveItemKey,
  isOpen,
  variant,
}) => {
  return (
    <div
      className={classNames(
        styles.menu,
        isOpen && styles.open,
        variant === 'sm' && styles.sm
      )}
    >
      {items.map((menuItem) => (
        <div key={menuItem.key}>
          <MenuItem
            setActiveItemKey={setActiveItemKey}
            activeItemKey={activeItemKey}
            item={menuItem}
          />
        </div>
      ))}
    </div>
  )
}

export default MenuItems
