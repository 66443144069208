import React from 'react'
import { useRouter } from 'next/router'
import { BiLogOut } from 'react-icons/bi'
import Card from 'components/common/Card/Card'
import { FlexRow } from 'components/common/Containers'
import { useLogOut } from 'hooks/useLogOut'
import { RouteEnum } from 'enums/RouteEnum'
import styles from './DashboardLogOut.module.scss'
import { Button } from 'components/common/Buttons/Button'

const DashboardLogOut = () => {
  const router = useRouter()

  const { mutateAsync } = useLogOut()

  return (
    <Card className={styles.wrapper} padding={[30, 65]}>
      <Button
        mode="text"
        className={styles.logOut}
        danger
        onClick={() => {
          mutateAsync().then(() => {
            router.push(`${RouteEnum.LOGIN}/?from=${router.pathname}`)
          })
        }}
      >
        <FlexRow align="center" gap="10px">
          <BiLogOut className={styles.icon} />
          Logout
        </FlexRow>
      </Button>
    </Card>
  )
}

export default DashboardLogOut
